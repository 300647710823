import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import phoneNumberRegex from '@common/validation/phoneNumberRegex';
import { GenericFormInputInterface } from '@sitecore/types/manual/GenericFormInputField';
import { InputTelephone } from '@sparky';

import { FormValues, GenericFormFC, GenericFormInputProps } from '../../util';

const NAME_FALLBACK = 'phoneNumberFormField';

function isGenericFormPhoneNumber(field: GenericFormInputInterface) {
  return field?.genericInputField !== undefined;
}

const GenericFormPhoneNumberInputField: GenericFormFC<GenericFormInputProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormPhoneNumber(fields)) {
    return null;
  }

  const { hint, label, placeholder } = fields?.genericInputField?.value ?? {};
  const name = fields?.genericInputField?.value?.name || NAME_FALLBACK;

  return (
    <InputTelephone
      autoComplete="tel"
      error={errors?.[name]?.message}
      hint={hint}
      label={label}
      placeholder={placeholder}
      {...register(name)}
    />
  );
};

GenericFormPhoneNumberInputField.yupValidationScheme = (fields: GenericFormInputInterface) => {
  const { requiredMessage, validationMessage } = fields?.genericInputField?.value ?? {};
  const name = fields?.genericInputField?.value?.name || NAME_FALLBACK;

  const schema = yup.string().matches(phoneNumberRegex, {
    excludeEmptyString: true,
    message: validationMessage,
  });

  return {
    [name]: requiredMessage ? schema.required(requiredMessage) : schema,
  };
};

export default GenericFormPhoneNumberInputField;
