import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { GenericFormNameInterface } from '@sitecore/types/manual/GenericFormNameField';
import { InputText, Grid } from '@sparky';

import { FormValues, GenericFormFC, GenericFormNameProps } from '../../util';

// Important: these names map to the field names expected by DC, so they should not just be changed
const INITIALS_FALLBACK = 'initials';
const PREPOSITION_FALLBACK = 'surnamePreposition';
const SURNAME_FALLBACK = 'surname';

function isGenericFormName(field: GenericFormNameInterface) {
  return field?.initialsFormField !== undefined;
}

const GenericFormNameField: GenericFormFC<GenericFormNameProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormName(fields)) {
    return null;
  }

  const {
    hint: initialsHint,
    label: initialsLabel,
    placeholder: initialsPlaceholder,
  } = fields?.initialsFormField?.value ?? {};
  const initialsName = fields?.initialsFormField?.value?.name || INITIALS_FALLBACK;
  const {
    hint: prepositionHint,
    label: prepositionLabel,
    placeholder: prepositionPlaceholder,
  } = fields?.surnamePrepositionFormField?.value ?? {};
  const prepositionName = fields?.surnamePrepositionFormField?.value?.name || PREPOSITION_FALLBACK;
  const {
    hint: surnameHint,
    label: surnameLabel,
    placeholder: surnamePlaceholder,
  } = fields?.surnameFormField?.value ?? {};
  const surnameName = fields?.surnameFormField?.value?.name || SURNAME_FALLBACK;

  return (
    <Grid columns={{ initial: '1', md: '2', lg: '3' }} gap="6">
      <InputText
        label={initialsLabel}
        error={errors?.[initialsName]?.message}
        hint={initialsHint}
        placeholder={initialsPlaceholder}
        {...register(initialsName)}
      />
      <InputText
        label={prepositionLabel}
        error={errors?.[prepositionName]?.message}
        hint={prepositionHint}
        placeholder={prepositionPlaceholder}
        {...register(prepositionName)}
      />
      <InputText
        label={surnameLabel}
        error={errors?.[surnameName]?.message}
        hint={surnameHint}
        placeholder={surnamePlaceholder}
        {...register(surnameName)}
      />
    </Grid>
  );
};

GenericFormNameField.yupValidationScheme = (fields: GenericFormNameInterface) => {
  const { requiredMessage: initialsRequiredMessage } = fields?.initialsFormField?.value ?? {};
  const initialsName = fields?.initialsFormField?.value?.name || INITIALS_FALLBACK;

  const { requiredMessage: prepositionRequiredMessage } = fields?.surnamePrepositionFormField?.value ?? {};
  const prepositionName = fields?.surnamePrepositionFormField?.value?.name || PREPOSITION_FALLBACK;

  const { requiredMessage: surnameRequiredMessage } = fields?.surnameFormField?.value ?? {};
  const surnameName = fields?.surnameFormField?.value?.name || SURNAME_FALLBACK;

  return {
    [initialsName]: initialsRequiredMessage ? yup.string().required(initialsRequiredMessage) : yup.string(),
    [prepositionName]: prepositionRequiredMessage ? yup.string().required(prepositionRequiredMessage) : yup.string(),
    [surnameName]: surnameRequiredMessage ? yup.string().required(surnameRequiredMessage) : yup.string(),
  };
};

export default GenericFormNameField;
