import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { RequestModels_Forms_V2_FormsRequestModelV2 } from '@monorepo-types/dc';

import { request } from '../client';

type PostFormV2 = {
  label: DC_Repositories_Base_Enumerations_Label;
  formId: string;
  businessUnit: string;
  requestBody: RequestModels_Forms_V2_FormsRequestModelV2;
  recaptchaToken?: string;
};
/**
 * PostFormV2
 * post the form
 * @returns void
 */
export function postFormV2({ label, formId, businessUnit, requestBody, recaptchaToken }: PostFormV2): Promise<void> {
  return request({
    method: 'POST',
    path: `/dxpweb/public/v2/${businessUnit}/${label}/forms/${formId}`,
    body: requestBody,
    headers: { recaptchaToken },
    errors: { 400: 'Bad Request' },
  });
}
