import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { GenericFormInputInterface } from '@sitecore/types/manual/GenericFormInputField';
import { InputNumber } from '@sparky';

import { FormValues, GenericFormFC, GenericFormInputProps } from '../../util';

const NAME_FALLBACK = 'numberFormField';

function isGenericNumberInput(field: GenericFormInputInterface) {
  return field?.genericInputField !== undefined;
}

const GenericFormNumberInputField: GenericFormFC<GenericFormInputProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericNumberInput(fields)) {
    return null;
  }
  const { hint, label, placeholder } = fields?.genericInputField?.value ?? {};
  const name = fields?.genericInputField?.value?.name || NAME_FALLBACK;

  return (
    <InputNumber
      error={errors?.[name]?.message}
      hint={hint}
      label={label}
      placeholder={placeholder}
      {...register(name)}
    />
  );
};

GenericFormNumberInputField.yupValidationScheme = (fields: GenericFormInputInterface) => {
  const { requiredMessage } = fields?.genericInputField?.value ?? {};
  const name = fields?.genericInputField?.value?.name || NAME_FALLBACK;

  return { [name]: requiredMessage ? yup.string().required(requiredMessage) : yup.string() };
};

export default GenericFormNumberInputField;
