import { FC, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { getCombinedLinkFieldValueHref } from '@common/link';
import logger from '@common/log';
import { toURL } from '@common/path';
import { stripAccolades } from '@common/string';
import { Recaptcha, RecaptchaRef } from '@components/Recaptcha/Recaptcha';
import { RecaptchaForm } from '@components/RecaptchaForm/RecaptchaForm';
import RichText from '@components/RichText/RichText';
import { useFormsPublicV2PostFormV2 } from '@dc/hooks';
import useDC from '@dc/useDC';
import { useRouter } from '@dxp-next';
import { Placeholder } from '@sitecore/common';
import { GenericFormRendering } from '@sitecore/types/manual/GenericForm';
import { Button, Heading, NotificationBox, Stack } from '@sparky';

import { ErrorType, FormValues, genericFormSchema, prepareRequest } from './util';

const GenericForm: FC<GenericFormRendering> = ({ fields, placeholders }) => {
  const recaptchaRef = useRef<RecaptchaRef>(null);

  const { label, businessUnit } = useDC();
  const { error, isError, isSuccess, send } = useFormsPublicV2PostFormV2();
  const { pushHref } = useRouter();

  const {
    description,
    title,
    formSubmitRedirectLink,
    senderIdOption: { value: senderId },
  } = fields;

  const [errorType, setErrorType] = useState<ErrorType | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isSuccess) return;

    const redirectLink = getCombinedLinkFieldValueHref(formSubmitRedirectLink.value);

    setErrorType(undefined);
    pushHref(toURL(redirectLink));
  }, [fields, isSuccess, pushHref]);

  useEffect(() => {
    if (!isError) return;

    logger.error('Wxd7AY', 'Failed to submit form', { error });
  }, [isError, error]);

  const resolver = yupResolver(genericFormSchema(placeholders['jss-form']));
  const methods = useForm<FormValues>({
    mode: 'onBlur',
    resolver,
  });

  const {
    formState: { errors },
  } = methods;

  const submitForm: SubmitHandler<FormValues> = async values => {
    setIsSubmitting(true);

    try {
      const requestBody = prepareRequest(senderId, values);

      await send({
        businessUnit,
        formId: stripAccolades(senderId),
        label,
        requestBody,
        recaptchaToken: values.recaptchaToken,
      });
    } catch (error) {
      logger.error('Wxd7AY', 'Failed to submit form', { error });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <RecaptchaForm onSubmit={submitForm} recaptchaRef={recaptchaRef}>
        <Stack gap="6">
          {title?.value && (
            <Heading as="h2" size="M">
              {title.value}
            </Heading>
          )}
          {description?.value && <RichText html={description.value}></RichText>}
          <Placeholder name="jss-form" />
          <Stack.Item>
            <Recaptcha hasError={!!errors.recaptchaToken} ref={recaptchaRef} />
          </Stack.Item>
          <Stack.Item>
            <Button type="submit" isLoading={isSubmitting}>
              {formSubmitRedirectLink?.value?.text}
            </Button>
          </Stack.Item>
        </Stack>
      </RecaptchaForm>
      {errorType ? (
        <NotificationBox
          isAlert
          text={<RichText html={`${errorType}ErrorNotification`} />}
          title={`${errorType}ErrorNotification`}
        />
      ) : null}
    </FormProvider>
  );
};

export default GenericForm;
