import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { GenericFormCheckboxInterface } from '@sitecore/types/manual/GenericFormCheckboxField';
import { Checkbox } from '@sparky';

import { FormValues, GenericFormCheckboxProps, GenericFormFC } from '../../util';

const NAME_FALLBACK = 'checkboxFormField';

function isGenericFormCheckbox(field: GenericFormCheckboxInterface) {
  return field?.checkboxFormField !== undefined;
}

const GenericFormCheckboxField: GenericFormFC<GenericFormCheckboxProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormCheckbox(fields)) {
    return null;
  }

  const { hint, label } = fields?.checkboxFormField?.value ?? {};
  const name = fields?.checkboxFormField?.value?.name || NAME_FALLBACK;

  return <Checkbox error={errors?.[name]?.message} hint={hint} label={label} {...register(name)} />;
};

GenericFormCheckboxField.yupValidationScheme = (fields: GenericFormCheckboxInterface) => {
  const { requiredMessage } = fields?.checkboxFormField?.value ?? {};
  const name = fields?.checkboxFormField?.value?.name || NAME_FALLBACK;

  return { [name]: requiredMessage ? yup.boolean().test('true', requiredMessage, value => !!value) : yup.boolean() };
};

export default GenericFormCheckboxField;
