import RichText from '@components/RichText/RichText';
import { GenericFormParagraphInterface } from '@sitecore/types/manual/GenericFormParagraph';
import { Heading, Text } from '@sparky';

import { GenericFormFC, GenericFormParagraphProps } from '../../util';

function isGenericFormParagraph(field: GenericFormParagraphInterface) {
  return field?.paragraph !== undefined;
}

const GenericFormParagraph: GenericFormFC<GenericFormParagraphProps> = ({ fields }) => {
  if (!isGenericFormParagraph(fields)) {
    return null;
  }
  const { paragraph } = fields;
  return (
    <>
      <Heading as="h1" size="XS">
        {paragraph.value.title}
      </Heading>
      <Text>
        <RichText html={paragraph.value.content} />
      </Text>
    </>
  );
};

export default GenericFormParagraph;
