import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { GenericFormInputInterface } from '@sitecore/types/manual/GenericFormInputField';
import { TextArea } from '@sparky';

import { FormValues, GenericFormFC, GenericFormInputProps } from '../../util';

const NAME_FALLBACK = 'textAreaFormField';

function isGenericFormTextArea(field: GenericFormInputInterface) {
  return field?.genericInputField !== undefined;
}

const GenericFormTextAreaInputField: GenericFormFC<GenericFormInputProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormTextArea(fields)) {
    return null;
  }

  const { hint, label, placeholder } = fields?.genericInputField?.value ?? {};
  const name = fields?.genericInputField?.value?.name || NAME_FALLBACK;

  return (
    // TODO: Rows needs to be added as a configurable parameter
    <TextArea placeholder={placeholder} hint={hint} label={label} error={errors?.[name]?.message} {...register(name)} />
  );
};

GenericFormTextAreaInputField.yupValidationScheme = (fields: GenericFormInputInterface) => {
  const { requiredMessage } = fields?.genericInputField?.value ?? {};
  const name = fields?.genericInputField?.value?.name || NAME_FALLBACK;

  return { [name]: requiredMessage ? yup.string().required(requiredMessage) : yup.string() };
};

export default GenericFormTextAreaInputField;
