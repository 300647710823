import { RequestModels_Forms_V2_FormsRequestModelV2 } from '@monorepo-types/dc';

import { FormValues } from './types';

const DATA_KEYS = [
  'emailAddress',
  'houseNumber',
  'houseNumberSuffix',
  'initials',
  'postalCode',
  'street',
  'surname',
  'surnamePreposition',
];

export function prepareRequest(senderIdOption: string, input: FormValues): RequestModels_Forms_V2_FormsRequestModelV2 {
  const data: Record<string, unknown> = {};
  const formFields: Array<{ key: string; value: string }> = [];

  for (const key in input) {
    if (key === 'recaptchaToken') continue;

    if (DATA_KEYS.includes(key)) {
      data[key] = input[key];
    } else {
      let value = input[key];

      if (Array.isArray(value)) {
        value = value.join(',');
      }

      formFields.push({ key, value: `${value}` });
    }
  }

  return {
    data: {
      houseNumber: 0, // necessary, as DC types wrongly mark houseNumber as non-optional
      ...data,
      formFields,
      destinationCode: senderIdOption,
      urlOrigin: window.location.href,
    },
  };
}
