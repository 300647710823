import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { GenericFormAddressInteface } from '@sitecore/types/manual/GenericFormAddressField';
import { InputText, Grid } from '@sparky';

import { FormValues, GenericFormAddressProps, GenericFormFC } from '../../util';

// Important: these names map to the field names expected by DC, so they should not just be changed
const POSTALCODE_FALLBACK = 'postalCode';
const HOUSENUMBER_FALLBACK = 'houseNumber';
const HOUSENUMBERSUFFIX_FALLBACK = 'houseNumberSuffix';

function isGenericFormAddress(field: GenericFormAddressInteface) {
  return field?.houseNumberFormField !== undefined;
}

const GenericFormAddressField: GenericFormFC<GenericFormAddressProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormAddress(fields)) {
    return null;
  }

  const {
    hint: postalCodeHint,
    label: postalCodeLabel,
    placeholder: postalCodePlaceholder,
  } = fields?.postalCodeFormField?.value ?? {};
  const postalCodeName = fields?.postalCodeFormField?.value?.name || POSTALCODE_FALLBACK;

  const {
    hint: houseNumberHint,
    label: houseNumberLabel,
    placeholder: houseNumberPlaceholder,
  } = fields?.houseNumberFormField?.value ?? {};
  const houseNumberName = fields?.houseNumberFormField?.value?.name || HOUSENUMBER_FALLBACK;

  const {
    hint: houseNumberSuffixHint,
    label: houseNumberSuffixLabel,
    placeholder: houseNumberSuffixPlaceholder,
  } = fields?.houseNumberSuffixFormField?.value ?? {};
  const houseNumberSuffixName = fields?.houseNumberSuffixFormField?.value?.name || HOUSENUMBERSUFFIX_FALLBACK;

  return (
    <Grid columns={{ initial: '1', md: '2', lg: '3' }} gap="6">
      <InputText
        error={errors?.[postalCodeName]?.message}
        hint={postalCodeHint}
        label={postalCodeLabel}
        placeholder={postalCodePlaceholder}
        {...register(postalCodeName)}
      />
      <InputText
        error={errors?.[houseNumberName]?.message}
        hint={houseNumberHint}
        label={houseNumberLabel}
        placeholder={houseNumberPlaceholder}
        {...register(houseNumberName)}
      />
      <InputText
        error={errors?.[houseNumberSuffixName]?.message}
        hint={houseNumberSuffixHint}
        label={houseNumberSuffixLabel}
        placeholder={houseNumberSuffixPlaceholder}
        {...register(houseNumberSuffixName)}
      />
    </Grid>
  );
};

GenericFormAddressField.yupValidationScheme = (fields: GenericFormAddressInteface) => {
  const { requiredMessage: postalCodeRequiredMessage } = fields?.postalCodeFormField?.value ?? {};
  const postalCodeName = fields?.postalCodeFormField?.value?.name || POSTALCODE_FALLBACK;

  const { requiredMessage: houseNumberRequiredMessage } = fields?.houseNumberFormField?.value ?? {};
  const houseNumberName = fields?.houseNumberFormField?.value?.name || HOUSENUMBER_FALLBACK;

  const { requiredMessage: houseNumberSuffixRequiredMessage } = fields?.houseNumberSuffixFormField?.value ?? {};
  const houseNumberSuffixName = fields?.houseNumberSuffixFormField?.value?.name || HOUSENUMBERSUFFIX_FALLBACK;

  return {
    [postalCodeName]: postalCodeRequiredMessage ? yup.string().required(postalCodeRequiredMessage) : yup.string(),
    [houseNumberName]: houseNumberRequiredMessage ? yup.string().required(houseNumberRequiredMessage) : yup.string(),
    [houseNumberSuffixName]: houseNumberSuffixRequiredMessage
      ? yup.string().required(houseNumberSuffixRequiredMessage)
      : yup.string(),
  };
};

export default GenericFormAddressField;
